import styled from 'styled-components';

import {
  View, Text, ButtonStyles, Icon, FileUploader, imageUploadTypes, DoorPhotoStore,
} from '@adac/core-view';

import { __ } from '@adac/core-model';

const Styles = styled(View)`
  display: grid;
  place-items: center;
  background-color: ${props => props.theme.colors.backgroundLight};
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);

  min-height: 320px;

  /* padding: 16px 0; */
  width: 100%;
  height: 100%;
  padding: 0;

  ${ButtonStyles} {

    &, & > button {
      width: 100%;
      height: 100%;

      * {
        text-align: center;
      }
    }
  }
`;

const NewImageTitle = styled(Text)`
  opacity: 0.5;
  margin-top: 16px;
`;

interface Props {
  doorStore: DoorPhotoStore;
}

export default ({ doorStore }: Props) => {
  function onFileLoaded(files: File[]) {
    const doorPhotos = files.map(file => ({
      file,
      url: URL.createObjectURL(file),
    }));
    if (doorPhotos && doorPhotos.length > 0) {
      doorStore.addDoorPhotos(doorPhotos);
    }
  }

  return (
    <Styles>
      <FileUploader
        onFileLoaded={(files) => onFileLoaded(files)}
        accept={imageUploadTypes}
        multiple
      >
        <Icon name="add-photo" color="grey" />
        <NewImageTitle>{__('addImage')}</NewImageTitle>
      </FileUploader>
    </Styles>
  );
};
