import {
  useContext,
} from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import {
  Page, Title, SubPage, Text, SubTitle, MapContainer, Button, View, InfoItem, useParams, Timer,
  ErrorMessage, useCustomerCoords, AddressLink, SmallText, Divider, ProductType,
  ChildrenProps,
} from '@adac/core-view';

import {
  Incident,
  __,
} from '@adac/core-model';

import StoreContext from '../../stores';
import { useStatusListeners } from '../../hooks/useStatusListener';

const LargeText = styled(Text)`
  font-size: ${props => props.theme.font.subTitle};
  margin-top: 8px;
`;

const SubContent = styled(SubPage)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ButtonContainer = styled(View)`
  margin: 16px auto;
`;

export const IncomingJobControls = observer(() => {
  const params = useParams();
  const { case: caseStore } = useContext(StoreContext);
  const companyId = params.rootId || caseStore.currentCompanyId;

  return (
    <>
      <ButtonContainer>
        {companyId && (
          <Button
            info
            title={__('SND_REJECT')}
            link={`/${companyId}/offer/reject`}
          />
        )}
      </ButtonContainer>

      <ButtonContainer>
        {companyId && (
          <Button
            cta
            id="sndTimeSuggestion"
            title={__('SND_ACCEPT')}
            link={`/${companyId}/offer/dispatch`}
          />
        )}
      </ButtonContainer>
    </>
  );
});

export const AdditionalIncidentNotes = ({ FacilityIssues, Notes }: Incident) => (
  <View>
    <SmallText bold>{__('Incident Notes')}</SmallText>
    {Notes && (
      <>
        <View
          paddingTop="10px"
          paddingBottom="10px"
        >
          <SmallText>{Notes}</SmallText>
        </View>
        <Divider fullWidth />
      </>
    )}
    {!!FacilityIssues && FacilityIssues?.map(issue => (
      <>
        <View
          paddingTop="10px"
          paddingBottom="10px"
        >
          <SmallText>{issue?.Damage?.DamageNotes}</SmallText>
          <SmallText>{issue?.Damage?.Description}</SmallText>
        </View>
        <Divider fullWidth />
      </>
    ))}
  </View>
);

const IncomingJobToDispatch = observer(({ children }: ChildrenProps) => {
  const params = useParams();
  const { case: caseStore, ui: uiStore } = useContext(StoreContext);
  const companyId = params.rootId || caseStore.currentCompanyId;

  const customerCoords = useCustomerCoords({ caseStore });

  // TODO: check if the companyId is exists
  if (!companyId) {
    uiStore.setOverlay(<ErrorMessage>{__('Company cannot be identified')}</ErrorMessage>);
    // TODO: better error handling, probably to apply login for company
    // return <>no companyId</>;
  }

  useStatusListeners(caseStore);

  return (
    <Page>
      <SubContent>
        <Title>{__('KeyEmergencyOrder')}</Title>
        <LargeText>{__('RemainingTime')}</LargeText>
        <Timer
          startTime={caseStore.getDispatchDriverTimeLimit}
          countdown
          onCountdown={<ErrorMessage>{__('TimeOver')}</ErrorMessage>}
        />

      </SubContent>

      <MapContainer
        customerPosition={customerCoords}
        style={{ height: '160px' }}
      />

      <SubContent>
        {children}

        <SubTitle>{__('informationAboutCustomer')}</SubTitle>

        <InfoItem
          header={__('DoorComplexity')}
          info={__(`complexity:${caseStore.complexity}`)}
        />

        <InfoItem header="Adresse" info={caseStore.customerDisguisedAddressString} />

        <ProductType productType={caseStore?.productType} />

        {
          customerCoords && <AddressLink {...customerCoords} />
        }

        {/* <Divider /> */}

        {/* <InfoItem header="Name" info={caseStore.customerName} /> */}

        {
          caseStore.description !== '' && <InfoItem header="Weitere Informationen" info={caseStore.description} />
        }

        {
          caseStore.facilityIssues && <AdditionalIncidentNotes {...caseStore.facilityIssues} />
        }

      </SubContent>
    </Page>
  );
});

export default IncomingJobToDispatch;
