import { useEffect } from 'react';
import { CaseStore, useParams } from '@adac/core-view';
import { CaseDTO } from '@adac/core-model';

type CallbackData = CaseDTO & {
  event?: string;

};
type Callback = (data: CallbackData) => void;

export function useStatusListeners(caseStore: CaseStore, callback?: Callback) {
  const params = useParams();

  useEffect(() => {
    caseStore.initSocketListeners(callback);
    return () => {
      caseStore.closeSocketListeners();
    };
  }, [callback, caseStore, params.root_path]);
}
