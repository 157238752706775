import * as fs from 'fs';
import * as path from 'path';
export function bufferFile(dirName, relPath) {
    return new Promise((resolve, reject) => {
        const filePath = path.join(dirName, relPath);
        fs.readFile(filePath, { encoding: 'utf8' }, (error, data) => {
            if (error)
                reject(error);
            else
                resolve(data);
        });
    });
}
export const urlifyFileName = (fileName, fileFormat = '', fileServerUrl = process.env.FILE_SERVER_URL) => {
    if (!fileServerUrl)
        throw new Error('File server url env var is missing.');
    if (fileName === null || fileName === void 0 ? void 0 : fileName.match(new RegExp(`^${fileServerUrl}`)))
        return fileName;
    return fileName ? `${fileServerUrl}/${fileName}${fileFormat ? `.${fileFormat}` : ''}` : undefined;
};
export const isStringData = (str) => str.startsWith('data:');
export const pathJoin = (...args) => path.join(...args);
