import { Button, LinkAutoPrefixer, useLocation } from '@adac/core-view';
import { __ } from '@adac/core-model';

export const FinalFormBackButton = () => {
  const location = useLocation();

  if (!location.pathname.match('final/report/(active|images)$')) {
    return null;
  }

  return (
    <LinkAutoPrefixer to="/" prefix="rootId">
      <Button cta title={__('Back')} />
    </LinkAutoPrefixer>
  );
};
