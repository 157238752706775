import { useContext } from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  CaseStatusList, getApiRoutes,
} from '@adac/core-model';
import {
  useLocation, DialogStyle, findMatchingRoute, matchStatus,
  RouteSwitcher, OverlayContentWrapper, CloseButton, getFinalReportTypeFromPathname, ViewProvider,
} from '@adac/core-view';

import StoreContext from '../../stores';

import ComingSoon from '../overlays/ComingSoon';
import ComissionConfirm from '../pages/ComissionConfirm';
import CommissionExtraDamage from '../pages/CommissionExtraDamage';

import FinalReportImages from '../pages/FinalReportImages';
import OpeningFailed from '../pages/OpeningFailed';
import AuthorizationRenderer from '../composite/AuthorizationRenderer';
import DispatchToDriver from '../pages/DispatchToDriver';
import DispatchToDriverAccepted from '../pages/DispatchToDriverAccepted';
import { HubbleCommissionFeedbackRetry } from '../composite/HubbleCommissionFeedbackRetry';
import { RejectCaseOnDispatching } from '../overlays/RejectCase';
import FinalReport from '../pages/FinalReport';

export const routeNames = {
  ...getApiRoutes().paths.pdf,
  ...getApiRoutes().paths.driver,
};

export default observer(() => {
  const stores = useContext(StoreContext);
  const location = useLocation();
  const { getPrefixedLink } = location;
  const finalReportType = getFinalReportTypeFromPathname(location.pathname);

  const routes: JSX.Element[] = [
    <Route
      path={getPrefixedLink(
        `/:companyId${routeNames.offerDispatch('dispatch')}`,
        matchStatus([CaseStatusList.SND_SELECTED, CaseStatusList.DRIVER_SELECTED], stores.case.status),
      )}
      exact
      render={props => (
        <DispatchToDriver {...props}>
          <HubbleCommissionFeedbackRetry />
        </DispatchToDriver>
      )}
    />,
    <Route
      path={getPrefixedLink(
        `/:companyId${routeNames.offerDispatch('reject')}`,
        matchStatus([CaseStatusList.SND_SELECTED, CaseStatusList.DRIVER_SELECTED], stores.case.status),
      )}
      exact
      component={RejectCaseOnDispatching}
    />,
    <Route
      path={getPrefixedLink(
        `/:companyId${routeNames.timesuggestionSaved}`,
        matchStatus([CaseStatusList.SND_SELECTED, CaseStatusList.DRIVER_SELECTED], stores.case.status),
      )}
      exact
      component={DispatchToDriverAccepted}
    />,

    // steps in the driver door opening worklow
    <Route
      path={getPrefixedLink(
        routeNames.commissionReport('view'),
        // NOTE: deleted conditional match, as we always have to show these screens for PDF generation, irrespectively of the actual state!
        // matchStatus([CaseStatusList.DRIVER_ARRIVED, CaseStatusList.OPENING_STARTED, CaseStatusList.EXTRA_DAMAGE_ONGOING, CaseStatusList.OPENING_COMPLETED, CaseStatusList.CASE_COMPLETED], stores.case.status),
      )}
      exact
      render={() => (
        <ComissionConfirm />
      )}
    />,
    <Route
      path={getPrefixedLink(
        routeNames.commissionReport(),
        // NOTE: deleted conditional match, as we always have to show these screens for PDF generation, irrespectively of the actual state!
        // matchStatus([CaseStatusList.DRIVER_ARRIVED, CaseStatusList.OPENING_STARTED, CaseStatusList.EXTRA_DAMAGE_ONGOING, CaseStatusList.OPENING_COMPLETED, CaseStatusList.CASE_COMPLETED], stores.case.status),
      )}
      exact
      render={() => (
        <AuthorizationRenderer>
          <ComissionConfirm />
        </AuthorizationRenderer>
      )}
    />,
    <Route
      path={getPrefixedLink(
        routeNames.commissionExtraDamage('view'),
        // NOTE: deleted conditional match, as we always have to show these screens for PDF generation, irrespectively of the actual state!
      )}
      exact
      render={() => (
        <CommissionExtraDamage />
      )}
    />,
    <Route
      path={getPrefixedLink(
        routeNames.commissionExtraDamage(),
        undefined,
        // matchStatus([CaseStatusList.OPENING_STARTED, CaseStatusList.EXTRA_DAMAGE_ONGOING, CaseStatusList.OPENING_COMPLETED, CaseStatusList.CASE_COMPLETED], stores.case.status),
        true,
      )}
      exact
      render={() => (
        <AuthorizationRenderer>
          <CommissionExtraDamage />
        </AuthorizationRenderer>
      )}
    />,

    <Route
      path={getPrefixedLink(
        routeNames.finalStatusReport('images'),
        matchStatus([CaseStatusList.OPENING_STARTED, CaseStatusList.EXTRA_DAMAGE_ONGOING, CaseStatusList.EDITING_EXTRA_DAMAGE], stores.case.status),
        true,
      )}
      exact
      render={() => (
        <AuthorizationRenderer>
          <FinalReportImages />
        </AuthorizationRenderer>
      )}
    />,
    <Route
      path={getPrefixedLink(
        routeNames.finalStatusReport('view', finalReportType),
        // NOTE: deleted conditional match, as we always have to show these screens for PDF generation, irrespectively of the actual state!
      )}
      exact
      render={() => <FinalReport />}
    />,
    <Route
      path={getPrefixedLink(
        routeNames.finalStatusReport(),
        // matchStatus([CaseStatusList.OPENING_STARTED, CaseStatusList.EXTRA_DAMAGE_ONGOING, CaseStatusList.OPENING_COMPLETED, CaseStatusList.CASE_COMPLETED], stores.case.status),
        undefined,
        true,
      )}
      exact
      render={() => (
        <AuthorizationRenderer>
          <FinalReport />
        </AuthorizationRenderer>
      )}
    />,
    <Route
      path={getPrefixedLink(
        routeNames.openingFailed,
      )}
      exact
      render={() => (
        <AuthorizationRenderer>
          <OpeningFailed />
        </AuthorizationRenderer>

      )}
    />,
  ];

  const foundMatchingRoute = !!findMatchingRoute({ location, routes });

  const isComingSoon = location.search.match(/[?&]coming-soon(\/|&|$)/gi) !== null;
  const { showOverlay, OverlayContent, isFullPageOverlay } = stores.ui;
  const hasValidRoute = foundMatchingRoute;
  const open = isComingSoon || hasValidRoute || showOverlay;

  return (
    <DialogStyle open={open} column end="true">
      {hasValidRoute && <ViewProvider><RouteSwitcher routes={routes} /></ViewProvider>}
      {isComingSoon && <ComingSoon />}
      {showOverlay && <OverlayContentWrapper fullPage={isFullPageOverlay}><CloseButton onClick={() => stores.ui.setOverlay()} /><>{OverlayContent}</></OverlayContentWrapper>}
    </DialogStyle>
  );
});
