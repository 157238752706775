/* eslint-disable no-return-await */
import React from 'react';

import { extractAddressLocationNameObject } from '@adac/core-view';
import { extractLocationCoordsWithConfidence, BillingAddressProps } from '@adac/core-model';
import { useFormikContext } from 'formik';
import { BillingAddressDataValues, emptyDefaultAddress } from '../components/pages/BillingAddressForm';
import { FinalReportFormValues } from '../components/pages/FinalReport';

export const extractCustomerLocationAddressProps = ({
  zip, place, street, houseNumber, latitude, longitude,
}: FinalReportFormValues) => ({
  zip, place, street, houseNumber, latitude, longitude,
});

export const validateBillingAddress = (billingAddress: BillingAddressDataValues) => !!(billingAddress.zip && billingAddress.place && billingAddress.street && billingAddress.houseNumber && billingAddress.firstName && billingAddress.lastName && billingAddress.title);

export const useBillingAddressFormValues = (
  billingAddressFromServer: BillingAddressProps | null | undefined,
) => {
  const { values, initialValues, setFieldValue }= useFormikContext<FinalReportFormValues>();

  const differentBillingAddressFromForm: BillingAddressDataValues = {
    ...extractCustomerLocationAddressProps(values),
    ...extractLocationCoordsWithConfidence(values),
    firstName: values.firstName,
    lastName: values.lastName,
    title: values.title,
  };

  const billingAddress: BillingAddressDataValues = differentBillingAddressFromForm
  || (billingAddressFromServer && extractAddressLocationNameObject(billingAddressFromServer))
  || emptyDefaultAddress;

  const isBillingAddressValid = validateBillingAddress(billingAddress);

  React.useEffect(() => {
    if (!validateBillingAddress(initialValues)) setFieldValue('showAddress', true);
  }, [initialValues, setFieldValue]);

  return {
    differentBillingAddress: billingAddress,
    isBillingAddressValid,
  };
};
