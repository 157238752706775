const DOOR_PHOTO_SELECTOR = 'doorPhoto';
const REPORT_DATA_SELECTOR = 'apiDataLoaded';
const SIGNATURE_DATA_SELECTOR = 'signaturePhoto';
const getFinalReportSelectors = props => (Object.assign(Object.assign({ REPORT_DATA_SELECTOR }, (((props === null || props === void 0 ? void 0 : props.photos) && (props === null || props === void 0 ? void 0 : props.photos.length) > 0) ? { DOOR_PHOTO_SELECTOR } : {})), ((props === null || props === void 0 ? void 0 : props.signature) ? { SIGNATURE_DATA_SELECTOR } : {})));
const getExtraDamageSelectors = () => ({
    REPORT_DATA_SELECTOR,
    SIGNATURE_DATA_SELECTOR,
});
const getComissionSelectors = props => (Object.assign({ REPORT_DATA_SELECTOR,
    SIGNATURE_DATA_SELECTOR }, (((props === null || props === void 0 ? void 0 : props.photos) && (props === null || props === void 0 ? void 0 : props.photos.length) > 0) ? { DOOR_PHOTO_SELECTOR } : {})));
const reportSelectors = {
    getFinalReportSelectors,
    getExtraDamageSelectors,
    getComissionSelectors,
    DOOR_PHOTO_SELECTOR,
    REPORT_DATA_SELECTOR,
    SIGNATURE_DATA_SELECTOR,
};
export default reportSelectors;
