import React from 'react';
import {
  Text,
  SubTitle,
} from '@adac/core-view';
import { AdditionalCost, __ } from '@adac/core-model';
import {
  AmountComponentProps,
  Cost, CostLayout, CostTitle, Dots,
} from './CostLayout';
import { CostWithDetails, CostWithDetailsProps } from './CostDetailLayout';

type AdditionalCostProps = {
  costs: AdditionalCost[];
} & AmountComponentProps;

export const AdditionalCosts = ({
  costs,
  amountComponent: AmountComponent,
}: AdditionalCostProps) => (
  <React.Fragment>
    {costs.map((cost) => {
      const costId = `${cost.costItemId} ${cost.description}`;
      if (cost.details) {
        const costWithDetails = cost as CostWithDetailsProps;
        return <CostWithDetails key={costId} {...costWithDetails} amountComponent={AmountComponent} />;
      }

      return <Cost key={costId} {...cost} amountComponent={AmountComponent} />;
    })}
  </React.Fragment>
);

export const CoveredCosts = ({ costs }: { costs: string[] }) => (
  <React.Fragment>
    <Text bold padding="8px 0 0 0" fontSize="1.3rem">{__('Included services')}:</Text>
    {costs.map((cost) => <CostTitle>{__(`desc:${cost}`)}</CostTitle>)}
  </React.Fragment>
);

export const TotalCost = ({ totalCost }: { totalCost: number }) => (
  <CostLayout>
    <SubTitle>{__('Amount')}</SubTitle>
    <Dots />
    <SubTitle>{totalCost} €</SubTitle>
  </CostLayout>
);
