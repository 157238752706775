import {
  CaseDTO, DriverActionType, __, getApiRoutes,
} from '@adac/core-model';
import { useContext } from 'react';

import { PostStore, request } from '@adac/core-view';
import StoreContext from '../stores';

export function useWorkflowDriverAction<Data>(caseAction: DriverActionType) {
  const { case: caseStore } = useContext(StoreContext);

  // TODO: DATA should be good to get derived from caseAction
  return async (data: Data) => {
    if (!caseStore.currentCompanyId) throw new Error(__('Company cannot be identified'));
    const driverAction = async () => request<CaseDTO, Data>(getApiRoutes().partner.case(caseStore.token).action.setStatus(caseAction, caseStore.currentCompanyId!), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${caseStore.accessToken}`,
      },
      data,
    });
    const sndActionStore = new PostStore(driverAction);
    const item = await sndActionStore.postItem(caseStore.token, data);
    return caseStore.handleStoreAction(sndActionStore, item);
  };
}
