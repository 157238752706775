import React from 'react';
import styled from 'styled-components';

import { Children } from '@adac/core-model';
import { View } from '@adac/core-view';

const Styles = styled(View)`
  border-radius: 4px;
  box-shadow: ${props => props.theme.effects.smallShadow};
`;

interface Props {
  children: Children;
}

export const ImageCarouselItem = ({ children }: Props) => (
  <Styles>
    {children}
  </Styles>
);
