import { RouterContextContainer } from '@adac/core-view';
import { LayoutContainer } from './components/layout';
import { useRedirectWithAccessToken, useRedirectWithCaseToken } from './hooks/useRedirect';

const App = () => {
  const redirectWithCaseToken = useRedirectWithCaseToken();
  const redirectWithAccessToken = useRedirectWithAccessToken();

  return (
    <RouterContextContainer
      redirectWithCaseToken={redirectWithCaseToken}
      redirectWithAccessToken={redirectWithAccessToken}
    >
      <LayoutContainer />
    </RouterContextContainer>
  );
};

export default App;
