import { useContext } from 'react';
import { observer } from 'mobx-react';

import {
  matchStatus, ErrorMessage, View, ChildrenProps,
} from '@adac/core-view';
import { CaseStatusList, __ } from '@adac/core-model';
import StoreContext from '../../stores';

interface HubbleCommissionFeedbackRetryProps extends ChildrenProps{}

export const HubbleCommissionFeedbackRetry = observer(({ children }: HubbleCommissionFeedbackRetryProps) => {
  const stores = useContext(StoreContext);
  if (matchStatus(CaseStatusList.DRIVER_SELECTED, stores.case.status)) {
    return (
      <>
        <ErrorMessage fullWidth border="solid 1px green">
          <View fullWidth border="solid 1px green">
            {__('Commission accept response was registered into OpenHome, ADAC confirmation failed, Company can force retry')}
          </View>
        </ErrorMessage>
        {children}
      </>
    );
  }
  return null;
});
